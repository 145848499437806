import React, { useEffect, useState } from 'react';
import './App.scss';

function App() {
    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);

    useEffect(() => {
        setWidth(window.screen.width);
        setHeight(window.screen.height);
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }, []);

    return (
        <div className="App">
            <a href="https://spin4spin.com" target='_blank'><img className='logo' src="/logo.svg" alt="spin4spin logo"/></a>
            <p className='text'>
                Ah shit, here we SPIN again...<br/>
                SPIN4SPIN на Хлебозаводе переехал!<br/>
                Теперь мы открылись в паре шагов отсюда - на территории Дизайн-завода Флакон.
            </p>
            <p className='text'>С нетерпением ждем вас в гости! 🖤</p>
            <p className='text'><a href="tel:+79779272006">+7 (977) 927-20-06</a></p>
            <p className='text'>Большая новодмитровская, 36/1<br/>300 м от вас</p>
            <div id="map"></div>
        </div>
    );
}

export default App;
